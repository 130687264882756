







































import { Component, Prop, Vue } from 'vue-property-decorator'

// Components
import Achievement from '@/components/views/progress/achievements/Achievement.vue'
// Types
import { AchievementResource } from '@/store/types/schema.progress'

@Component({
  components: {
    Achievement,
  },
})
export default class AchievementCard extends Vue {
  $refs!: {
    achievement: Achievement,
    card: HTMLElement,
    description: HTMLDivElement,
  }

  @Prop({ required: true })
  private achievementInfo!: AchievementResource

  @Prop({ default: false })
  private isNotOpening!: boolean

  private observer = new ResizeObserver(() => this.handleResizeObserver())
  private descriptionHeight = 24
  private cardHeight = this.$vuetify.breakpoint.width > 1024 ? 148 : 120

  private get progressThumbWidth () {
    return this.achievementInfo.progress / this.achievementInfo.maxProgress * 100
  }

  private get classList () {
    return {
      'is-lock': !this.achievementInfo.receivedAt,
      'is-not-opening': this.isNotOpening,
      'is-secret': this.achievementInfo.isSecret,
      'theme--dark': this.$vuetify.theme.dark,
      'theme--light': !this.$vuetify.theme.dark,
    }
  }

  private mounted () {
    if (this.achievementInfo.receivedAt) {
      this.observer.observe(this.$refs.card)
      this.cardHeight = 120
    } else {
      this.cardHeight = 100
    }

    this.handleResizeObserver()
  }

  private beforeDestroy() {
    this.observer.unobserve(this.$refs.card)
  }

  private handleResizeObserver () {
    this.descriptionHeight = (this.$refs.description)?.scrollHeight ?? 24
    this.cardHeight = (this.$refs.card)?.scrollHeight ?? this.cardHeight
  }

  private isCanPlay = false

  private playAnimation () {
    if (this.achievementInfo.receivedAt) {
      this.$refs.achievement?.play()
      this.isCanPlay = true
    }
  }

  private stopAnimation () {
    if (this.achievementInfo.receivedAt) {
      this.isCanPlay = false
    }
  }
}
