





















import { Component } from 'vue-property-decorator'

// Store
import MasterProgressAchievementsModule from '@/store/modules/master/progress_achievements'
// Components
import AchievementCard from '@/components/views/progress/achievements/AchievementCard.vue'
import ProgressViewAchievementModal from '@/components/modals/progress/ProgressViewAchievementModal.vue'
// Types
import { AchievementResource } from '@/store/types/schema.progress'
import NotifyMixin from '@/mixins/NotifyMixin'

export interface AchievementResourceWithIndexes extends AchievementResource {
  isNextIndex: boolean,
  isPrevIndex: boolean,
}

@Component({
  components: {
    AchievementCard,
    ProgressViewAchievementModal,
  },
})
export default class ProgressAchievements extends NotifyMixin {
  private isVisibleAchievementInfo = false
  private currentAchievementIndex = 0

  private get currentAchievementInfo (): AchievementResourceWithIndexes {
    return {
      ...this.visibleAchievements[this.currentAchievementIndex],
      isNextIndex: this.currentAchievementIndex + 1 < this.visibleAchievements.length,
      isPrevIndex: this.currentAchievementIndex - 1 >= 0,
    }
  }

  private get visibleAchievements (): AchievementResource[] {
    return MasterProgressAchievementsModule.achievements
      .filter((item) => !item.isSecret || (item.isSecret && item.receivedAt))
  }

  private mounted () {
    MasterProgressAchievementsModule.getAchievements()
  }

  private handleAchievementOpen(index: number) {
    this.isVisibleAchievementInfo = true
    this.currentAchievementIndex = index
  }
}
