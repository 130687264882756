

































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

// components
import Achievement from '@/components/views/progress/achievements/Achievement.vue'
import Dialog from '@/components/modals/Dialog.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
import ProgressSubjectChip from '@/components/_uikit/progress/ProgressSubjectChip.vue'
// types
import { AchievementResourceWithIndexes } from '@/views/master/progress/ProgressAchievements.vue'

@Component({
  components: {
    Achievement,
    Dialog,
    ModalWrapper,
    ProgressSubjectChip,
  },
})
export default class ProgressViewAchievementModal extends Vue {
  @Prop({ required: true })
  private visible!: boolean

  @Prop({ required: true })
  private achievementInfo!: AchievementResourceWithIndexes

  private isAchievementChange = false

  private get progressThumbWidth () {
    return this.achievementInfo.progress / this.achievementInfo.maxProgress * 100
  }

  private get dialogWidth() {
    if (this.$vuetify.breakpoint.width > 1365) {
      return 600
    } else {
      return 500
    }
  }

  private localVisible = this.visible

  private handleNavigate (emitName: string) {
    this.isAchievementChange = false

    this.$nextTick(() => {
      this.isAchievementChange = true
    })

    this.$emit(emitName)
  }

  private handleClose() {
    this.localVisible = false
    this.$emit('closeModal')
    this.isAchievementChange = false
  }

  @Watch('visible')
  private watchVisible(value: boolean) {
    this.localVisible = value

    if (value) {
      this.isAchievementChange = true
    }
  }
}
